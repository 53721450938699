<template>
    <div class="addedit">
        <el-dialog class="zc_dialog_box" :visible.sync="dialogVisible" width="500px" :close-on-click-modal="false"
            @close="cancelBtn" :model="form" :destroy-on-close="true">
            <div slot="title">
                <div class="name">
                    {{ Object.values(activeItem).length > 0 ? "编辑" : "新增" }}
                </div>
            </div>
            <div class="formbox">
                <el-form ref="form" :model="form" label-width="100px" :rules="rules">
                    <el-form-item label="滤镜名称" prop="name">
                        <el-input v-model="form.name" placeholder="请输入滤镜名称"></el-input>
                    </el-form-item>
                    <el-form-item label="分类" prop="category_id">
                        <el-select v-model="form.category_id" placeholder="请选择分类">
                            <el-option v-for="item in classifyOptions" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="滤镜缩略图" prop="thumbnail">
                        <img-upload :width="100" :height="100" type="img_1" @upload="successUpload">
                            <div class="picture_img_upload">
                                <div class="img_yes" v-if="form.thumbnail">
                                    <el-image :src="form.thumbnail" fit="scale-down"></el-image>
                                    <div class='succ_btn'>
                                        <i class="el-icon-picture-outline-round" @click.stop="playImg(form.thumbnail)"></i>
                                        <i class="el-icon-circle-plus-outline"></i>
                                    </div>
                                </div>
                                <div class="img_no" v-else>
                                    <i class="el-icon-plus"></i>
                                    <div>上传图片</div>
                                </div>
                            </div>
                        </img-upload>
                    </el-form-item>
                    <el-form-item label="状态" prop="status">
                        <el-switch v-model="form.status" :active-value="1" :inactive-value="2"></el-switch>
                    </el-form-item>
                    <el-form-item label="滤镜参数">
                        <el-input type="textarea" v-model="form.ext" resize="none" maxlength="500"
                            :autosize="{ minRows: 4, maxRows: 4 }"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer">
                <el-button class="btnBgColor_grey" size="small" @click="dialogVisible = false">取 消</el-button>
                <el-button class="btnBgColor_blue" size="small" type="primary" @click="submit" :loading="loading"
                    v-if="Object.values(activeItem).length == 0">确 定</el-button>
                <el-button class="btnBgColor_blue" size="small" type="primary" @click="submitEdit" :loading="loading"
                    v-if="Object.values(activeItem).length > 0">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 预览图片 -->
        <img-preview ref="img"></img-preview>
    </div>
</template>

<script>
import ImgPreview from '@/components/img_preview/index.vue'
export default {
    components: {
        ImgUpload: (resolve) => require(['@/components/file_upload/index'], resolve),
        ImgPreview
    },
    props: {
        classifyOptions: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            dialogVisible: false,
            form: {
                category_id: '',
                name: '',
                thumbnail: '',
                status: 1,
                ext: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入滤镜名称', trigger: 'blur' },
                    {
                        min: 2,
                        max: 10,
                        message: '长度在 2 到 10 个字符',
                        trigger: 'blur'
                    }
                ],
                category_id: [
                    { required: true, message: '请选择分类', trigger: 'change' }
                ],
                status: [{ required: true, message: '请选择分类', trigger: 'blur' }],
                thumbnail: [{ required: true, message: '请上传图片', trigger: 'change' }]
            },
            loading: false,
            activeItem: {}
        }
    },
    watch: {},
    computed: {},
    methods: {
        openDialog(item) {
            this.dialogVisible = true
            this.activeItem = item ?? {}
            item.ext = item?.filter_json?.ext || ''
            if (Object.values(this.activeItem).length > 0) {
                for (const key in item) {
                    if (Object.hasOwn(this.form, key)) {
                        this.form[key] = item[key]
                    }
                }
            }
        },
        cancelBtn() {
            Object.assign(this.$data.form, this.$options.data().form)
            this.$refs.form.clearValidate()
        },
        successUpload(item) {
            this.form.thumbnail = item.long_url
        },
        // 提交
        submit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.loading = true
                    this.$materialApi
                        .material_filter_add({
                            ...this.form
                        })
                        .then((res) => {
                            this.loading = false
                            this.$message({
                                message: res.message,
                                type: res.code === 1000 ? 'success' : 'error'
                            })
                            if (res.code === 1000) {
                                this.dialogVisible = false
                                this.$emit('reset')
                            }
                        })
                }
            })
        },
        // 编辑
        submitEdit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.loading = true
                    this.$materialApi
                        .material_filter_edit(this.activeItem.id, {
                            ...this.form
                        })
                        .then((res) => {
                            this.loading = false
                            this.dialogVisible = false
                            this.$message({
                                message: res.message,
                                type: res.code === 1000 ? 'success' : 'error'
                            })
                            if (res.code === 1000) {
                                this.$emit('reset')
                            }
                        })
                }
            })
        },
        // 预览图片
        playImg(url){
            this.$refs.img.openDialog(url)
        },
    }
}
</script>
<style lang="scss" scoped>
.addedit {
    ::v-deep .el-select {
        width: 100%;
    }
}
.succ_btn{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    border-radius: 5px;
    background: rgba($color: #000000, $alpha: 0.5);
    display: none;
    align-items: center;
    justify-content: space-around;
    i{
        font-size: 26px;
        color: #fff;
    }
}
.img_yes:hover .succ_btn{
    display: flex;
}
</style>
